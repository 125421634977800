﻿$image-root: '/PMIContent/';

@function asset-v6-url($path) {
    @return url($image-root + 'V6/' + $path);
}

@function legacy-image-url($path) {
    @return url($image-root + 'styles/images/' + $path);
}

@function asset-site-url($path) {
    @return url($site-image-root + $path);
}
