﻿@import '_helpers';
$theme-name: 'blue';
$theme-image-root: $image-root + '/v6/images/' + $theme-name + '/';
$colors-links-primary: #e8802e;
$colors-links-offset: #fff;

$colors-theme-alpha: #2a7de2;
$colors-theme-beta: #f2802b;
$colors-theme-gamma: #fab225;
$colors-theme-delta: #a08e6c;
$colors-theme-epsilon: #4f4857;
$colors-theme-kappa: #fef4ed;
$colors-theme-lamda: #f3e8e8;
$colors-theme-sigma: #c97023;
$modern-bg: #e3e3e3;

$colors-error-red: #db2629;

/* pop-ups */
div.loader2 > div.bg {
    background-color: rgba(0,0,0,.5);
}
div.loader2 > div.spinner {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
    background-color: #fff;
}

div.loader2 > div.spinner {
    &.default-image {
        background-image: asset-v6-url("images/blue/waitwheel.gif");
        background-size: 50px 50px;
        background-position: center;
        background-repeat: no-repeat;
    }
}

div.loader2 {
    background-color: transparent;
}
